// This file can be replaced during build by using the `fileReplacements` array.
// The list of file replacements can be found in `angular.json`.

export class CommonEnvironment {
  static accessCode: string = '2018';
  static ads: boolean = false;
  static adsPrice: number = 750;
  static campaignsOptions: any = {
    addDay: 1,
    hour: 6
  };
  static content: any = {
    welcomeMessage: {
      en: '',
      nb: ''
    }
  };
  static cookies: any = {
    analytics: true,
    login: {
      google: true,
      facebook: true
    }
  }
  static demo: boolean = false;
  static facebookAppID: string = '';
  static favorites: boolean = false;
  static language: any = {
    multilanguage: true,
    defaultLanguage: 'nb'
  }
  static newsletterEnabled: boolean = true;
  static onlyForRegisteredUsers: boolean = false;
  static protectedByCode: boolean = false;
  static placeholderImage: string = 'placeholder-facebook.jpg';
  static preselectedCategories: string[] = [];
  static production: any = true;
  static publishingDate: any = '';
  static registration: any = {
    allowPublic: true,
    allowLoginProviders: true
  };
  static rsvpOptions: any = {};
  static synchro: any[] = [
    {
      destination: "visitnorway",
      name: "Visit Norway",
      baseURL: "https://www.visitnorway.no/event/hsk/{slug}/",
      optional: true,
      checked: true
    }
  ];
  static templates: any[] = [
    {
      id: 'grid-view',
      name: 'Grid view',
      multiIframe: false
    },
    {
      id: 'list-view',
      name: 'List view',
      multiIframe: true
    }
  ];
  static theme: string = 'classic';
  static ui: any = {
    defaults: {},
    labelPrice: {
      nb: 'CC',
      en: 'CC'
    },
    showAddEventForVisitors: true,
    showOnMetadata: ["mode", "streamingURL", "share", "add-to-my-calendar", "categories", "activityType", "tickets", "facebookURL", "moreInfoURL", "videosURL", "organizers", "email", "phone", "ageRestriction", "venue"],
    showOnFooter: {
      aboutPage: true,
      ads: true,
      facebook: true,
      contact: true,
      contactExternalLink: null,
      feed: true,
      organizers: true,
      privacy: true,
      what: true,
      why: true
    },
    showOnFilter: {
      sortBy: 'date'
    },
    showOnForm: {
      activityType: false,
      eatTogether: false,
      featuredEvent: false,
      featuredEventOnlyAdmins: false,
      fieldEvent: false,
      importImageFromURL: false,
      indoors: false,
      maxCategories: 5,
      maxCharsTitle: 70,
      maxCharsVenue: 150,
      maxCharsOrganizer: 150,
      maxCharsSummary: 300,
      maxCharsDesc: 3000,
      maxActivityType: 3,
      maxTags: 20,
      mazemap: false,
      municipality: false,
      rsvp: false,
      summary: false,
      unlisted: false,
      venueNotes: true,
      venueCountry: 'no',
      venueUnknown: false
    },
    showOnFrontpage: {
      searchBar: false
    },
    showOnHeader: {
      searchBar: true
    },
    showOnMenu: [{
      name_en: 'Admin',
      name_nb: 'Admin',
      internal: true,
      link: '/admin',
      icon: 'settings_applications',
      loggedIn: true,
      admin: true
    },{
      name_en: 'Warnings',
      name_nb: 'Advarsler',
      internal: true,
      link: '/warnings',
      icon: 'warning',
      loggedIn: true,
      admin: true
    },{
      name_en: 'Account',
      name_nb: 'Konto',
      internal: true,
      link: '/account',
      icon: 'account_circle',
      loggedIn: true,
      admin: true
    },{
      name_en: 'My events',
      name_nb: 'Mine arrangementer',
      internal: true,
      link: '/my-events',
      icon: 'list',
      loggedIn: true,
      admin: true
    },{
      name_en: 'My teams',
      name_nb: 'Mine lag',
      internal: true,
      link: '/my-teams',
      icon: 'group_work',
      loggedIn: true,
      admin: true
    }],
    showWelcomeMessage: true
  };

  static vmFestEnabled: boolean = false;
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
